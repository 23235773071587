import { useQuery } from "@blitzjs/rpc"
import getAvailableStudents from "../queries/getAvailableStudents"

export const useAvailableStudents = () => {
  const [data] = useQuery(
    getAvailableStudents,
    {},
    {
      staleTime: 3600,
      cacheTime: 3600,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  return data ?? []
}
