import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import setActiveStudent from "../../shared/mutations/setActiveStudent"
import { Chip, Grid, ListItemButtonProps } from "@mui/material"
import getActiveStudent from "../../shared/queries/getActiveStudent"
import sortBy from "lodash/sortBy"
import { useSchoolConfig } from "../../shared/hooks/useSchoolConfig"
import { AddLoginCodeChip } from "../../feature-add-login-code/components/AddLoginCodeChip"
import { useAvailableStudents } from "../../shared/hooks/useAvailableStudents"
import uniqBy from "lodash/uniqBy"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"

type Props = {
  student: ReturnType<typeof useActiveStudent>
}
export const StudentSwitch = ({ student }: Props) => {
  const availableStudents = useAvailableStudents()
  const siblings = student.siblings?.students.filter((s) => s.id !== student.id) || []
  const allAvailableStudents = uniqBy(
    [...siblings, ...availableStudents].filter((s) => s.id !== student.id),
    "id"
  )

  const schoolConfig = useSchoolConfig()
  const [setActiveStudentMutation, { isLoading }] = useMutation(setActiveStudent)

  if (allAvailableStudents.length === 0 && !schoolConfig?.useLoginCodes) return null

  const handleClickSibling =
    (siblingId: number): ListItemButtonProps["onClick"] =>
    (event) => {
      event.preventDefault()
      void setActiveStudentMutation({ studentId: siblingId }).then(() => {
        void invalidateQuery(getActiveStudent)
      })
    }

  const allStudents = sortBy([student, ...allAvailableStudents], "name")

  return (
    <Grid container spacing={1} mt={2}>
      {allStudents.map((sibling) => (
        <Grid item key={sibling.id}>
          <Chip
            label={sibling.name}
            variant={sibling.id === student.id ? "filled" : "outlined"}
            clickable={sibling.id !== student.id}
            onClick={handleClickSibling(sibling.id)}
          />
        </Grid>
      ))}
      {schoolConfig?.useLoginCodes && (
        <Grid item>
          <AddLoginCodeChip />
        </Grid>
      )}
    </Grid>
  )
}
