import { Grid, Typography } from "@mui/material"
import { CloudPhoto } from "src/app/core/components/CloudPhoto"
import { useLightBox } from "src/app/core/hooks/useLightBox"
import { isTouchDevice } from "src/app/core/libs/isTouchDevice"
import { useYearCollection } from "../../shared/hooks/useYearCollection"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"
import { PhotoDialog } from "../../feature-photo-dialog/components/PhotoDialog"
import { DraggablePhoto } from "./DraggablePhoto"
import sortBy from "lodash/sortBy"
import { sortYearCollection } from "../../shared/util"
import { Gallery } from "src/app/core/components/Gallery"
import { NlToBr } from "../../../core/components/NlToBr"
import { useCurrentLanguage } from "../../../core/hooks/useCurrentLanguage"
import { actions, PackageProduct, ProductOptionDispatch, State } from "../util/productOption"
import { useEffect } from "react"

const isTouch = isTouchDevice()
type Props = {
  packageProduct: PackageProduct
  dispatch: ProductOptionDispatch
  selectedOptions?: State[number]
}
export const ProductDetailAll = ({ packageProduct, dispatch, selectedOptions }: Props) => {
  const locale = useCurrentLanguage()
  const student = useActiveStudent()
  const yearCollection = useYearCollection(student.id, useActiveSchoolYear())
  const photos = sortYearCollection(yearCollection)
  const lightBox = useLightBox()
  const allowedPhotoVariations = packageProduct.product.limitPhotoVariation.map(
    (photoVariation) => photoVariation.id
  )

  const filteredPhotos =
    allowedPhotoVariations.length === 0
      ? photos
      : photos.filter(
          (photo) => photo.variation && allowedPhotoVariations.includes(photo.variation.id)
        )

  const notAllPhotos = filteredPhotos.length !== photos.length

  useEffect(() => {
    if (notAllPhotos && (selectedOptions?.length ?? 0) === 0) {
      filteredPhotos.forEach((photo, index) => {
        dispatch(actions.selectPhoto(packageProduct.id, index, photo.id))
      })
    }
  }, [dispatch, filteredPhotos, notAllPhotos, packageProduct.id, selectedOptions?.length])

  return (
    <>
      {packageProduct.product.name?.[locale] && (
        <Typography sx={{ mb: 2 }}>
          <NlToBr text={packageProduct.product.name[locale]} />
        </Typography>
      )}

      {packageProduct.product.description?.[locale] && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          <NlToBr text={packageProduct.product.description[locale]} />
        </Typography>
      )}

      <Gallery
        photos={filteredPhotos}
        itemHeight={100}
        onClick={(index) => lightBox.onOpen(index)}
      />

      {lightBox.isOpen && filteredPhotos[lightBox.index] && (
        <PhotoDialog
          // @ts-ignore
          photos={[filteredPhotos[lightBox.index]]}
          activeIndex={lightBox.index}
          onClose={lightBox.onClose}
        />
      )}
    </>
  )
}
