import { FC, PropsWithChildren } from "react"
import { alpha, styled } from "@mui/material"
import { shouldForwardProp } from "src/app/core/libs/shouldForwardProp"

type SelectButtonProps = {
  isSelected: boolean
  isOK: boolean
}

const radius = 32
const border = 2

const SelectButton = styled("div", {
  shouldForwardProp: shouldForwardProp<SelectButtonProps>(["isSelected", "isOK"]),
})<SelectButtonProps>`
  position: absolute;
  z-index: 99;
  right: 4px;
  top: 4px;
  background-color: ${({ theme, isSelected, isOK }) =>
    isOK && isSelected
      ? theme.palette.success.main
      : isSelected
      ? alpha(theme.palette.primary.main, 1)
      : "rgba(255,255,255, 0.6)"};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.primary.contrastText : "initial"};
  border-radius: 100%;
  width: ${radius}px;
  height: ${radius}px;
  line-height: ${radius - border}px;
  font-size: 1.2rem;
  font-weight: 700;
  border: ${border}px solid white;
  text-align: center;
  &:hover {
    background-color: ${({ theme, isSelected, isOK }) =>
      isOK && isSelected
        ? theme.palette.success.main
        : isSelected
        ? alpha(theme.palette.primary.main, 0.8)
        : "rgba(255, 255, 255, 1)"};
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.palette.primary.contrastText : "initial"};
  }
`

type Props = {
  selectIndex?: number
  isOK: boolean
  onClick: () => void
}
export const SelectIndicator: FC<PropsWithChildren<Props>> = ({
  children,
  selectIndex,
  isOK,
  onClick,
}) => {
  return (
    <>
      {children}
      <SelectButton
        isSelected={typeof selectIndex === "number"}
        isOK={isOK}
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        {selectIndex}
      </SelectButton>
    </>
  )
}
