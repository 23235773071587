import * as z from "zod"

export const PackageProductOptionsInput = z.array(
  z.object({
    packageProductId: z.number(),
    options: z.array(
      z.object({
        photoId: z.number(),
        variantSku: z.string().optional(),
        quantity: z.number().default(1),
      })
    ),
  })
)

export const UpdateShoppingCartWithPackageInput = z.object({
  priceListId: z.number().nullable(),
  packageId: z.number(),
  year: z.number(),
  packageProductOptions: PackageProductOptionsInput.optional(),
})

export const UpdateShoppingCartLineQuantityInput = z.object({
  cartLineId: z.number(),
  quantity: z.number(),
})

export const ProductOptionsInput = z.array(
  z.object({
    photoId: z.number(),
    variantSku: z.string().optional(),
    quantity: z.number().default(1),
  })
)

export const UpdateShoppingCartWithProductInput = z.object({
  studentId: z.number().optional(),
  priceListId: z.number().nullable(),
  productId: z.number(),
  productOptions: ProductOptionsInput.optional(),
  cartLineId: z.number().optional(),
  forceAdd: z.boolean().optional(),
})
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UpdateShoppingCartWithProductInput = z.infer<typeof UpdateShoppingCartWithProductInput>
