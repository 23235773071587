import { Fragment } from "react"

export const NlToBr = ({ text }: { text: string }) => {
  return (
    <>
      {text.split("\\n").map((item, key) => {
        return (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        )
      })}
    </>
  )
}
