import Image from "next/legacy/image"
import { useQuery } from "@blitzjs/rpc"
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material"
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import { useCurrentLanguage } from "src/app/core/hooks/useCurrentLanguage"
import getProduct from "../queries/getProduct"
import { formatMoney } from "../../../core/libs/money"
import { AddProductToCartButton } from "../../feature-shopping-cart/components/AddProductToCartButton"
import { useTranslation } from "next-i18next"
import { FormStepField } from "../../../core/components/formFields/FormStepField"
import { useState } from "react"
import { PhotoDialog } from "../../feature-photo-dialog/components/PhotoDialog"
import { useLightBox } from "../../../core/hooks/useLightBox"

type Props = {
  id: number
}
export const OtherProductDetail = ({ id }: Props) => {
  const { t } = useTranslation("shop")
  const locale = useCurrentLanguage()
  const [product] = useQuery(
    getProduct,
    { productId: id },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )
  const [quantity, setQuantity] = useState(1)
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const lightBox = useLightBox()

  if (!product) {
    return null
  }
  const variant = product.variants[0]
  const productPhoto = product.productPhotos?.photos[0]
  const price = (variant?.price ?? 0) * quantity

  const addToCart = (
    <Box display="flex" mt={8} mb={4} justifyContent="center" alignItems="center" flexWrap="wrap">
      <Box mr={2}>
        <FormStepField name="quantity" onChange={setQuantity} value={quantity} />
      </Box>
      <Typography sx={{ mr: 4 }} display="flex" flexDirection="column">
        <small>{t("price")}</small>
        <span style={{ marginTop: -4 }}>{formatMoney(price)}</span>
      </Typography>
      <Box mt={isXs ? 2 : 0}>
        <AddProductToCartButton
          productId={id}
          priceListId={product.priceListId}
          name={product?.name?.[locale]}
          productOptions={
            variant && productPhoto
              ? [{ photoId: productPhoto.id, quantity, variantSku: variant.sku }]
              : []
          }
          disabled={!Boolean(variant) || !productPhoto}
        />
      </Box>
    </Box>
  )

  return (
    <>
      <section>
        <Typography variant="h1" gutterBottom>
          {product?.name?.[locale]}
        </Typography>
        <Typography variant="h2" sx={{ mb: 2 }}>
          {product?.description?.[locale]}
        </Typography>

        {(product.productPhotos?.photos.length || 0) > 2 && addToCart}

        <Grid container spacing={2}>
          {product.productPhotos?.photos.map((photo, index) => (
            <Grid item sm={6} key={photo.id}>
              <Image
                src={photo.publicId}
                width={photo.width || 400}
                height={photo.height || 400}
                alt={product?.name?.[locale]}
                onClick={() => lightBox.onOpen(index)}
              />
            </Grid>
          ))}
        </Grid>

        {addToCart}
      </section>
      {product.productPhotos?.photos &&
        lightBox.isOpen &&
        product.productPhotos.photos[lightBox.index] && (
          <PhotoDialog
            // @ts-ignore
            photos={product.productPhotos.photos}
            activeIndex={lightBox.index}
            onClose={lightBox.onClose}
          />
        )}
    </>
  )
}
