import { Button, ButtonProps, styled } from "@mui/material"
import CloseIcon from "mdi-material-ui/Close"

const ButtonWrap = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: 60,
  height: 60,
  zIndex: 1,
}))

export const CloseButton = (btnProps: ButtonProps) => {
  return (
    <ButtonWrap color="secondary" variant="text" {...btnProps}>
      <CloseIcon fontSize="large" />
    </ButtonWrap>
  )
}
