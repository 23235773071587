import { useQuery } from "@blitzjs/rpc"
import { Grid, Theme, useMediaQuery } from "@mui/material"
import orderBy from "lodash/orderBy"
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import getPriceList from "../queries/getPriceList"
import { ProductCard } from "./ProductCard"
import { PackageDetail } from "./PackageDetail"
import { ProductDetail } from "./ProductDetail"
import { ProductType } from "db"
import { OtherProductDetail } from "./OtherProductDetail"
import { BookDetail } from "./BookDetail"
import { DownloadProductDetail } from "./DownloadProductDetail"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"

export const FeatureProducts = () => {
  const isXXS = useMediaQuery((theme: Theme) => {
    return "@media (max-width:480px)"
  })
  const student = useActiveStudent()

  const [priceList] = useQuery(
    getPriceList,
    {
      schoolGroupId: student.schoolGroupId,
    },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )

  if (!priceList) {
    return null
  }

  return (
    <Grid container spacing={1}>
      {priceList.packages.map((pkg, index) => (
        <Grid item key={pkg.id} xs={isXXS ? 12 : 6} md={pkg.cardWidth} alignSelf="stretch">
          <ProductCard
            id={pkg.id}
            title={pkg.name?.nl}
            price={pkg.price}
            priceStartingFrom={pkg.priceStartingFrom}
            description={pkg.summary?.nl}
            icon={pkg.icon}
            slug={pkg.slug}
          >
            <PackageDetail id={pkg.id} />
          </ProductCard>
        </Grid>
      ))}
      {priceList.products.map((product) => (
        <Grid item key={product.id} xs={isXXS ? 12 : 6} md={product.cardWidth} alignSelf="stretch">
          <ProductCard
            id={product.id}
            title={product.name?.nl}
            price={orderBy(product.variants, (variant) => variant.price)[0]?.price ?? 0}
            priceStartingFrom={
              product.variants.length > 1 || (product.variants[0]?.photoTypePricing.length ?? 0) > 1
            }
            description={product.summary?.nl}
            color="secondary"
            icon={product.icon}
            slug={product.slug}
          >
            {product.type === ProductType.PRINTS && <ProductDetail id={product.id} />}
            {product.type === ProductType.BOOK && <BookDetail id={product.id} />}
            {product.type === ProductType.OTHER && <OtherProductDetail id={product.id} />}
            {product.type === ProductType.DOWNLOAD && <DownloadProductDetail id={product.id} />}
          </ProductCard>
        </Grid>
      ))}
    </Grid>
  )
}
