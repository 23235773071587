import { useState, MouseEvent, useEffect } from "react"
import Plus from "mdi-material-ui/Plus"
import { Box, Grid, ListItemText, MenuItem, Popover, Select, Typography } from "@mui/material"
import { Photo } from "db"
import CloseCircleIcon from "mdi-material-ui/CloseCircle"
import { useCurrentLanguage } from "src/app/core/hooks/useCurrentLanguage"
import { CloudPhoto } from "src/app/core/components/CloudPhoto"
import { FormStepField } from "src/app/core/components/formFields/FormStepField"
import { formatMoney } from "src/app/core/libs/money"
import { Variant } from "../util/productOption"
import { PhotoSelectorPopover } from "./PhotoSelectorPopover"
import { RemoveButton, Selector } from "./PhotoSelector.styles"
import { useDrop } from "react-dnd"
import { DndTypes, DropResult } from "../util/DndTypes"
import { isTouchDevice } from "src/app/core/libs/isTouchDevice"
import { ProductThumbnail } from "../../../core/components/ProductThumbnail"
import { Thumbnail } from "@prisma/client"
import { thumbnail } from "@cloudinary/url-gen/actions/resize"

const isTouch = isTouchDevice()

type Props = {
  onRemove?: () => void
  onSelect: (photo: Photo, addSlot?: boolean) => void
  onSelectVariant: (variant: Variant) => void
  onChangeQuantity?: (quantity: number) => void
  selectedPhoto?: Photo | null
  variants?: Variant[]
  selectedVariantSku?: string | null
  quantity?: number
  horizontalLayout?: boolean
  photosIfSinglePhotoType?: Photo[] | false
  thumbnail?: Thumbnail | null
}
export const PhotoSelector = ({
  onRemove,
  onSelect,
  onSelectVariant,
  onChangeQuantity,
  selectedPhoto,
  variants,
  selectedVariantSku,
  quantity,
  horizontalLayout,
  photosIfSinglePhotoType,
  thumbnail,
}: Props) => {
  const lang = useCurrentLanguage()

  const [{ isOver }, drop] = useDrop(() => ({
    accept: DndTypes.PHOTO,
    drop: (item: DropResult) => {
      onSelect(item.photo)
      const variant = variants?.find(({ sku }) => selectedVariantSku === sku)
      if (variant) onSelectVariant(variant)
      return { name: "PhotoSelector" }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  // Auto select variant if only 1
  useEffect(() => {
    if (!selectedVariantSku && selectedPhoto && (variants || []).length === 1) {
      onSelectVariant(variants?.[0]!)
    }
  }, [selectedVariantSku, onSelectVariant, selectedPhoto, variants])

  // Auto select photo if only 1
  const singlePhoto =
    Array.isArray(photosIfSinglePhotoType) &&
    photosIfSinglePhotoType.length === 1 &&
    photosIfSinglePhotoType[0]

  useEffect(() => {
    if (singlePhoto && !selectedPhoto) {
      onSelect(singlePhoto, false)
    }
  }, [singlePhoto, onSelect, selectedPhoto])

  return (
    <>
      <Grid
        container
        flexDirection={(singlePhoto && selectedPhoto) || horizontalLayout ? "row" : "column"}
        spacing={2}
      >
        <Grid item ref={isTouch ? undefined : drop} sx={{ position: "relative" }}>
          {singlePhoto && selectedPhoto ? (
            <ProductThumbnail photo={selectedPhoto} thumbnail={thumbnail} />
          ) : (
            <Selector onClick={handleClick} className={isOver ? "is-over" : ""} disabled={open}>
              {selectedPhoto ? (
                <ProductThumbnail photo={selectedPhoto} thumbnail={thumbnail} height={75} />
              ) : (
                <Plus />
              )}
            </Selector>
          )}
          {selectedPhoto && onRemove && !singlePhoto && (
            <RemoveButton onClick={onRemove}>
              <CloseCircleIcon fontSize="medium" />
            </RemoveButton>
          )}
        </Grid>

        <Grid item flexGrow={1} flexShrink={1}>
          {selectedPhoto && (variants || []).length > 1 ? (
            <Select
              size="small"
              value={selectedVariantSku}
              onChange={(event) => {
                const variant = variants?.find(({ sku }) => event.target.value === sku)
                if (variant) onSelectVariant(variant)
              }}
              fullWidth
            >
              {variants?.map((variant) => (
                <MenuItem value={variant.sku} key={variant.sku}>
                  <ListItemText
                    primary={variant.name?.[lang]}
                    secondary={variant.description?.[lang]}
                    primaryTypographyProps={{
                      sx: { whiteSpace: "pre-wrap" },
                    }}
                  />
                  {variant.price ? (
                    <Typography variant="body2" color="text.secondary">
                      {formatMoney(variant.price)}
                    </Typography>
                  ) : null}
                </MenuItem>
              ))}
            </Select>
          ) : (
            selectedPhoto &&
            (variants || []).length === 1 && (
              <>
                <Typography>{variants?.[0]?.name?.[lang]}</Typography>
                {variants?.[0]!.price ? (
                  <Typography variant="body2" color="text.secondary">
                    {formatMoney(variants?.[0]!.price!)}
                  </Typography>
                ) : null}
              </>
            )
          )}
        </Grid>

        {selectedPhoto && typeof quantity === "number" && onChangeQuantity && (
          <Grid item>
            <FormStepField value={quantity} onChange={onChangeQuantity} />
          </Grid>
        )}
      </Grid>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box pl={1} pr={1} pt={3} pb={3} width={120 * 3}>
          {open && (
            <PhotoSelectorPopover
              photos={photosIfSinglePhotoType || []}
              onSelect={(...args) => {
                onSelect(...args)
                const variant = variants?.find(({ sku }) => selectedVariantSku === sku)
                if (variant) onSelectVariant(variant)
                handleClose()
              }}
            />
          )}
        </Box>
      </Popover>
    </>
  )
}
