import { Photo } from "db"
import { Box, Portal, styled, useMediaQuery, useTheme } from "@mui/material"
import { CloudPhoto } from "src/app/core/components/CloudPhoto"
import { CloseButton } from "src/app/core/components/CloseButton"
import { FC, useEffect, useState } from "react"
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Thumbs } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import { Swiper as SwiperClass, ThumbsOptions } from "swiper/types"
import { DownloadPhotoFab } from "../../../core/components/DownloadPhotoFab"

type Props = {
  photos: (Photo & { highRes?: string })[]
  activeIndex: number
  onClose: () => void
  enableDownload?: boolean
  onChangeIndex?: (index: number) => void
}

const Dialog = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1400;
`
const Overlay = styled("div")`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
`

const DialogContent = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DialogPhoto = styled("div")`
  height: 80%;
  width: 80%;
`
const SwiperWrapper = styled("div")`
  width: 100%;
  height: 100%;

  & .swiper {
    height: 100%;
    position: static;
    max-height: 1024px;
    max-width: 1024px;
  }

  & .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
const ThumbSwiperWrapper = styled("div")`
  position: absolute;
  bottom: 2vh;
  left: 10vw;
  width: 80vw;
  height: 7vh;

  & .swiper {
    height: 100%;
  }
`

export const PhotoDialog: FC<Props> = ({
  photos,
  onClose,
  activeIndex,
  children,
  enableDownload,
  onChangeIndex,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null)
  const [thumbsSwiper, setThumbsSwiper] = useState<ThumbsOptions["swiper"]>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    // @ts-ignore
    swiperRef?.slideTo(activeIndex)
  }, [swiperRef, activeIndex])

  return (
    <Portal>
      <Dialog>
        <Overlay onClick={onClose} />
        <DialogContent>
          <DialogPhoto>
            <SwiperWrapper>
              <Swiper
                // @ts-ignore
                onSwiper={setSwiperRef}
                // pagination={{
                //   type: "progressbar",
                // }}
                thumbs={{ swiper: thumbsSwiper }}
                navigation={true}
                keyboard={{
                  enabled: true,
                }}
                modules={[FreeMode, Navigation, Thumbs]}
                onSlideChange={(swiper) => {
                  if (swiper.activeIndex !== activeIndex && onChangeIndex) {
                    onChangeIndex(swiper.activeIndex)
                  }
                }}
              >
                {photos.map((photo, photoIndex) => {
                  const ratio = (photo.width || 1) / (photo.height || 1)

                  return (
                    <SwiperSlide key={photo.id}>
                      <CloudPhoto
                        photo={photo}
                        updatedAt={photo.updatedAt?.toISOString()}
                        transformation={"fos_large_watermark"}
                        highRes={photo.highRes}
                        layout="fill"
                        objectFit="contain"
                        priority={photoIndex < activeIndex + 2 && photoIndex > activeIndex - 2}
                      />
                      <Box
                        position="absolute"
                        bottom={8}
                        right={8}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        {children}

                        {(enableDownload || photo.highRes) && (
                          <DownloadPhotoFab publicId={photo.publicId} />
                        )}
                      </Box>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </SwiperWrapper>
          </DialogPhoto>
          {photos.length > 1 && (
            <ThumbSwiperWrapper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={4}
                slidesPerView={isMobile ? 4 : 10}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {photos.map((photo) => {
                  return (
                    <SwiperSlide key={photo.id}>
                      <CloudPhoto
                        photo={photo}
                        updatedAt={photo.updatedAt?.toISOString()}
                        transformation={"fos_thumb"}
                        layout="fill"
                        objectFit="cover"
                        // priority
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </ThumbSwiperWrapper>
          )}
          <CloseButton onClick={onClose} />
        </DialogContent>
      </Dialog>
    </Portal>
  )
}
