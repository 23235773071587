import { useQuery } from "@blitzjs/rpc"
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import { Typography, Alert, Box, useMediaQuery, Theme, Grid } from "@mui/material"
import { useTranslation } from "next-i18next"
import { AddPackageToCartButton } from "src/app/shop/feature-shopping-cart/components/AddPackageToCartButton"
import { formatMoney } from "src/app/core/libs/money"
import { useCurrentLanguage } from "src/app/core/hooks/useCurrentLanguage"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import getPackage from "../queries/getPackage"
import { ProductDetailAll } from "./ProductDetailAll"
import { ProductDetailFixedQuantity } from "./ProductDetailFixedQuantity"
import { Fragment, useReducer, useState } from "react"
import {
  createInitialState,
  productOptionReducer,
  stateToPackageProductOptions,
} from "../util/productOption"
import Image from "next/legacy/image"
import { NlToBr } from "src/app/core/components/NlToBr"

type Props = {
  id: number
}
export const PackageDetail = ({ id }: Props) => {
  const year = useActiveSchoolYear()
  const locale = useCurrentLanguage()
  const { t } = useTranslation("shop")
  const isBiggerScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const [pkg] = useQuery(
    getPackage,
    { packageId: id },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )
  const [state, dispatch] = useReducer(
    productOptionReducer,
    createInitialState(
      pkg?.products
        .filter(
          (pkgProduct) =>
            typeof pkgProduct.qtyIncluded === "number" ||
            pkgProduct.product.limitPhotoVariation.length > 0
        )
        .map((pkgProduct) => [pkgProduct.id, pkgProduct.minQtyIncluded ?? pkgProduct.qtyIncluded])
    )
  )

  if (!pkg)
    return (
      <section>
        <Alert severity="error">{t("fetchProductError")}</Alert>
      </section>
    )

  const packageProductOptions = stateToPackageProductOptions(state)

  const addToCart = (
    <>
      <Typography sx={{ mr: 4 }}>
        <small>{t("price")}</small>
        <br />
        <span style={{ display: "inline-block", whiteSpace: "nowrap" }}>
          {formatMoney(pkg.price)}
        </span>
      </Typography>
      <AddPackageToCartButton
        year={year}
        packageId={id}
        priceListId={pkg.priceListId}
        name={pkg?.name?.[locale]}
        packageProductOptions={packageProductOptions || []}
        disabled={packageProductOptions === false}
      />
    </>
  )

  return (
    <section>
      <Typography variant="h1" gutterBottom>
        {pkg?.name?.[locale]}
      </Typography>

      {pkg?.description?.[locale] && (
        <Typography variant="body2" sx={{ mb: 3 }}>
          <NlToBr text={pkg.description[locale]} />
        </Typography>
      )}

      {
        /*isBiggerScreen &&*/ <Box display="flex" mt={2} mb={2} justifyContent="center">
          {addToCart}
        </Box>
      }

      {
        /*isBiggerScreen && */ <Typography variant="h2" sx={{ mb: 2 }}>
          {t("includedInPackage")}
        </Typography>
      }

      {pkg.products.map((pkgProduct) => {
        return (
          <Fragment key={pkgProduct.id}>
            {pkgProduct.product.productPhotos?.photos && (
              <Grid container spacing={1} mb={2}>
                {pkgProduct.product.productPhotos?.photos.map((productPhoto) => (
                  <Grid item sm={6} flex={1} key={productPhoto.id}>
                    <Image
                      src={productPhoto.publicId}
                      width={400}
                      height={267}
                      alt={pkg.name?.[locale]}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <Box mb={2}>
              {
                typeof pkgProduct.qtyIncluded === "number" ? (
                  <ProductDetailFixedQuantity
                    packageProduct={pkgProduct}
                    dispatch={dispatch}
                    selectedOptions={state[pkgProduct.id]}
                    key={pkgProduct.id}
                  />
                ) : (
                  /*isBiggerScreen ?*/ <ProductDetailAll
                    key={pkgProduct.id}
                    selectedOptions={state[pkgProduct.id]}
                    packageProduct={pkgProduct}
                    dispatch={dispatch}
                  />
                ) /*: null*/
              }
            </Box>
          </Fragment>
        )
      })}

      <Box display="flex" mt={8} justifyContent="center">
        {addToCart}
      </Box>
    </section>
  )
}
