import { useReducer, useState } from "react"
import { useQuery } from "@blitzjs/rpc"
import { Box, Grid, Typography } from "@mui/material"
import Image from "next/legacy/image"
import { NlToBr } from "src/app/core/components/NlToBr"
import { useCurrentLanguage } from "src/app/core/hooks/useCurrentLanguage"
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import getProduct from "../queries/getProduct"
import {
  createInitialState,
  productOptionReducer,
  stateToProductOptions,
} from "../util/productOption"
import { ProductDetailVariableQuantity } from "./ProductDetailVariableQuantity"
import { FormStepField } from "src/app/core/components/formFields/FormStepField"
import { formatMoney } from "src/app/core/libs/money"
import { useTranslation } from "next-i18next"
import { AddProductToCartButton } from "../../feature-shopping-cart/components/AddProductToCartButton"
import { calculatePrice } from "src/app/core/libs/money/calculatePrice"

type Props = {
  id: number
}

export const BookDetail = ({ id }: Props) => {
  const { t } = useTranslation("shop")
  const [quantity, setQuantity] = useState(1)
  const locale = useCurrentLanguage()
  const [product] = useQuery(
    getProduct,
    { productId: id },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )
  const [state, dispatch] = useReducer(
    productOptionReducer,
    createInitialState([[product?.id ?? 0, 1]])
  )

  // TODO: For now we only support 1 variant
  const productVariant = product?.variants.find((variant) => variant.sku === product.defaultVariant)

  if (!product || !productVariant) return null

  const productOptions = stateToProductOptions(state, product.id)

  const addToCart = (
    <>
      <Box mr={2}>
        <FormStepField name="quantity" onChange={setQuantity} value={quantity} />
      </Box>
      <Typography sx={{ mr: 4 }}>
        <small>{t("price")}</small>
        <br />
        <span style={{ display: "inline-block", whiteSpace: "nowrap" }}>
          {formatMoney(
            calculatePrice(quantity, productVariant.tieredPricing, productVariant.price)
          )}
        </span>
      </Typography>
      <AddProductToCartButton
        productId={id}
        priceListId={product.priceListId}
        name={product?.name?.[locale]}
        productOptions={(productOptions || []).map((option) => ({ ...option, quantity }))}
        disabled={productOptions === false || productOptions?.length === 0}
      />
    </>
  )

  return (
    <section>
      <Typography variant="h1" gutterBottom>
        {product.name?.[locale]}
      </Typography>

      {product.description?.[locale] && (
        <Typography variant="body2" sx={{ mb: 3 }}>
          <NlToBr text={product.description[locale]} />
        </Typography>
      )}

      {product.productPhotos?.photos && (
        <Grid container spacing={1} mb={2}>
          {product.productPhotos?.photos.map((productPhoto) => (
            <Grid item sm={6} flex={1} key={productPhoto.id}>
              <Image
                src={productPhoto.publicId}
                width={productPhoto.width || 400}
                height={productPhoto.height || 400}
                alt={product.name?.[locale]}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Box display="flex" mt={4} mb={4} justifyContent="center" alignItems="center" flexWrap="wrap">
        {addToCart}
      </Box>

      <ProductDetailVariableQuantity
        product={product}
        variant={productVariant}
        dispatch={dispatch}
      />

      <Box display="flex" mt={4} mb={4} justifyContent="center" alignItems="center" flexWrap="wrap">
        {addToCart}
      </Box>
    </section>
  )
}
