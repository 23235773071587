import { useYearCollection } from "../../shared/hooks/useYearCollection"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { sortYearCollection } from "../../shared/util"
import { Gallery } from "src/app/core/components/Gallery"
import { SelectIndicator } from "./SelectIndicator"
import { Photo, Student } from "@prisma/client"
import { Typography } from "@mui/material"

type Props = {
  student: Student
  onClickItem: (photo: Photo) => void
  selected: number[]
  isOK: boolean
  onClickSelect: (photo: Photo) => void
}
export const ProductDetailVariableQuantityCollection = ({
  student,
  onClickItem,
  selected,
  isOK,
  onClickSelect,
}: Props) => {
  const yearCollection = useYearCollection(student.id, useActiveSchoolYear())
  const photos = sortYearCollection(yearCollection)

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2, mt: 2 }}>
        {student.name}
      </Typography>
      <Gallery
        photos={photos}
        itemHeight={100}
        onClick={(index, photo) => onClickItem(photo)}
        renderItem={(index, photo, item) => {
          const selectedIndex = selected.indexOf(photo.id)
          return (
            <SelectIndicator
              selectIndex={selectedIndex >= 0 ? selectedIndex + 1 : undefined}
              isOK={isOK}
              onClick={() => onClickSelect(photo)}
            >
              {item}
            </SelectIndicator>
          )
        }}
      />
    </>
  )
}
