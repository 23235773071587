import DownloadIcon from "mdi-material-ui/Download"
import { Fab } from "@mui/material"
import { useEffect, useState } from "react"

type Props = {
  publicId: string
}
export const DownloadPhotoFab = ({ publicId }: Props) => {
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if (isDownloading) {
      const tid = setTimeout(() => setIsDownloading(false), 1500)
      return () => clearTimeout(tid)
    }
  }, [isDownloading])

  return (
    <Fab
      color="secondary"
      size="medium"
      href={`/api/photo/download?publicId=${publicId}`}
      onClick={(e) => {
        e.stopPropagation()
        setIsDownloading(true)
      }}
      disabled={isDownloading}
      sx={{ boxShadow: "none" }}
    >
      <DownloadIcon />
    </Fab>
  )
}
