import { FC } from "react"
import { DrawerProps } from "@mui/material"
import { CloseButton } from "src/app/core/components/CloseButton"
import { useDebounce } from "src/app/core/hooks/useDebounce"
import { Sidebar } from "src/app/core/components/Sidebar"

type Props = DrawerProps & {}

export const ProductSidebar: FC<Props> = ({ open, onClose, children }) => {
  const debouncedIsOpen = useDebounce(open, open ? 100 : 250)

  return (
    <Sidebar open={open} anchor="right" onClose={onClose}>
      <CloseButton onClick={(e) => onClose?.(e, "backdropClick")} />
      {debouncedIsOpen && children}
    </Sidebar>
  )
}
