import { useQuery } from "@blitzjs/rpc"
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import { Grid, Typography, Box, useMediaQuery, Theme, Divider } from "@mui/material"
import { useTranslation } from "next-i18next"
import { useCurrentLanguage } from "src/app/core/hooks/useCurrentLanguage"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { useReducer } from "react"
import { Photo } from "db"
import sortBy from "lodash/sortBy"
import { formatMoney } from "src/app/core/libs/money"
import getProduct from "../queries/getProduct"
import {
  actions,
  createInitialState,
  Options,
  productOptionReducer,
  State,
  stateToProductOptions,
  Variant,
} from "../util/productOption"
import { PhotoSelector } from "./PhotoSelector"
import { AddProductToCartButton } from "../../feature-shopping-cart/components/AddProductToCartButton"
import { useGetPhotosIfSinglePhotoType } from "../hooks/useGetPhotosIfSinglePhotoType"
import { NlToBr } from "../../../core/components/NlToBr"
import {
  calculatePrice,
  calculatePriceForProductOptions,
} from "../../../core/libs/money/calculatePrice"
import getFullShoppingCart from "../../feature-checkout/queries/getFullShoppingCart"
import { ThenArg } from "../../../core/types"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"

function shoppingCartToOptionState(
  shoppingCart: ThenArg<ReturnType<typeof getFullShoppingCart>>,
  product: ThenArg<ReturnType<typeof getProduct>>,
  studentId: number
): State {
  const cartLine = shoppingCart?.lines.find(
    (line) => line.products[0]?.productId === product?.id && line.studentId === studentId
  )

  if (!cartLine) return createInitialState([[product?.id ?? 0, 1]])

  // @ts-ignore
  const options: Options = cartLine.products.flatMap((product) =>
    product.options.map((option) => ({
      photoId: null,
      photo: option.photo,
      variant: option.productVariant,
      quantity: option.quantity,
    }))
  )

  return {
    [cartLine.products[0]?.productId ?? ""]: [
      ...options,
      {
        photoId: null,
        photo: null,
        variant: null,
        quantity: 1,
      },
    ],
  }
}

type Props = {
  id: number
}
export const ProductDetail = ({ id }: Props) => {
  const year = useActiveSchoolYear()
  const student = useActiveStudent()
  const locale = useCurrentLanguage()
  const { t } = useTranslation("shop")
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const [product] = useQuery(
    getProduct,
    { productId: id },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )
  const [shoppingCart] = useQuery(getFullShoppingCart, {})
  const [state, dispatch] = useReducer(
    productOptionReducer,
    shoppingCartToOptionState(shoppingCart, product, student.id)
  )

  if (!product) {
    return null
  }

  const options = state[product.id] ?? []

  const price = calculatePriceForProductOptions(options)
  const productOptions = stateToProductOptions(state, product.id)

  // Sort variants by price + filter out variants for given photo
  const getVariants = (photo: Photo | null) =>
    sortBy(product.variants, (v) => v.price).filter((variant) => {
      const variationIds = variant.photoTypePricing.map((ptp) => ptp.variationId)
      if (variationIds.length === 0 || !photo?.variationId) return true

      return variationIds.includes(photo.variationId)
    })

  return (
    <section>
      <Typography variant="h1" gutterBottom>
        {product?.name?.[locale]}
      </Typography>

      {product?.description?.[locale] && (
        <Typography variant="body2" sx={{ mb: 3 }}>
          <NlToBr text={product.description[locale]} />
        </Typography>
      )}

      <Typography variant="h2" sx={{ mb: 2 }}>
        {t("selectPhotos")}
      </Typography>

      <Grid container spacing={4}>
        {options.map((option, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <PhotoSelector
              onRemove={() => {
                dispatch(actions.deselectPhoto(product.id, index))
              }}
              onSelect={(photo: Photo, addSlot = true) => {
                dispatch(actions.selectPhoto(product.id, index, photo))
                if (addSlot) dispatch(actions.addSlot(product.id))
              }}
              onSelectVariant={(variant: Variant) =>
                dispatch(actions.selectVariant(product.id, index, variant))
              }
              onChangeQuantity={(quantity) =>
                dispatch(actions.setQuantity(product.id, index, quantity))
              }
              selectedPhoto={option.photo}
              selectedVariantSku={option.variant?.sku || product.defaultVariant}
              variants={getVariants(option.photo)}
              quantity={option.quantity}
              horizontalLayout={isMd}
              photosIfSinglePhotoType={false}
              thumbnail={product.thumbnail}
            />
            {isXs && <Divider sx={{ mt: 4 }} />}
          </Grid>
        ))}
      </Grid>

      <Box display="flex" mt={8} justifyContent="center">
        <Typography sx={{ mr: 4 }}>
          <small>{t("price")}</small>
          <br />
          {formatMoney(price)}
        </Typography>
        <AddProductToCartButton
          productId={id}
          priceListId={product.priceListId}
          name={product?.name?.[locale]}
          productOptions={productOptions || []}
          disabled={productOptions === false}
        />
      </Box>
    </section>
  )
}
