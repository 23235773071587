import { ThenArg } from "src/app/core/types"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"
import { useYearCollection } from "../../shared/hooks/useYearCollection"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { sortYearCollection } from "../../shared/util"
import getProduct from "../queries/getProduct"

export function useGetPhotosIfSinglePhotoType(
  product: Partial<ThenArg<ReturnType<typeof getProduct>>>
) {
  const student = useActiveStudent()
  const yearCollection = useYearCollection(student.id, useActiveSchoolYear())
  const photos = sortYearCollection(yearCollection)

  if (!product) return false

  const photoTypePricing = product.variants?.flatMap((variant) => variant.photoTypePricing)

  if (photoTypePricing?.length !== 1 && product.limitPhotoVariation?.length === 0) return false
  const firstPhotoTypePricing = photoTypePricing?.[0]
  const limitPhotoVariationIds = product.limitPhotoVariation?.map((variation) => variation.id)

  return photos.filter((photo) => {
    return (
      photo.variationId &&
      (photo.variationId === firstPhotoTypePricing?.variationId ||
        limitPhotoVariationIds?.includes(photo.variationId))
    )
  })
}
