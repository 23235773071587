import { useQuery } from "@blitzjs/rpc"
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import getActiveStudent from "../queries/getActiveStudent"
import { NotFoundError } from "blitz"

export const useActiveStudent = () => {
  const [fetchedStudent] = useQuery(
    getActiveStudent,
    {},
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )

  if (!fetchedStudent) {
    throw new NotFoundError()
  }

  return fetchedStudent
}
