import { Photo } from "@prisma/client"
import { Images, ImageWrapper } from "./Gallery.styles"
import { CloudPhoto } from "./CloudPhoto"
import { ReactElement } from "react"

type MinimalPhoto = Photo & {
  updatedAt?: Photo["updatedAt"]
}

type GalleryProps<TPhoto> = {
  photos: TPhoto[]
  itemHeight?: number
  onClick?: (index: number, photo: TPhoto) => void
  renderItem?: (index: number, photo: TPhoto, item: ReactElement) => ReactElement
}

export const Gallery = <TPhoto extends MinimalPhoto>({
  photos,
  itemHeight = 200,
  onClick,
  renderItem,
}: GalleryProps<TPhoto>) => {
  return (
    <Images>
      {photos.map((photo, index) => {
        const width = photo.width ?? 1
        const height = photo.height ?? 1

        const item = (
          <CloudPhoto
            photo={photo}
            updatedAt={photo.updatedAt?.toISOString()}
            transformation={"fos_thumb"}
            layout="fill"
            objectFit="contain"
            style={{ borderRadius: 4 }}
          />
        )

        return (
          <ImageWrapper
            key={`${photo.id}-${index}`}
            style={{
              width: (width * itemHeight) / height,
              flexGrow: (width * itemHeight) / height,
              cursor: onClick ? "pointer" : "default",
            }}
            onClick={() => onClick?.(index, photo)}
          >
            <i
              style={{
                paddingBottom: `${(height / width) * 100}%`,
                display: "block",
              }}
            />
            {renderItem ? renderItem(index, photo, item) : item}
          </ImageWrapper>
        )
      })}
    </Images>
  )
}
