import { Box, Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import { Photo } from "db"
import { PhotoSelector } from "./PhotoSelector"
import {
  actions,
  PackageProduct,
  ProductOptionDispatch,
  Variant,
  State,
} from "../util/productOption"
import { useGetPhotosIfSinglePhotoType } from "../hooks/useGetPhotosIfSinglePhotoType"
import { NlToBr } from "src/app/core/components/NlToBr"
import { useCurrentLanguage } from "src/app/core/hooks/useCurrentLanguage"
import { useEffect } from "react"

type Props = {
  packageProduct: PackageProduct
  dispatch: ProductOptionDispatch
  selectedOptions?: State[number]
}

export const ProductDetailFixedQuantity = ({
  packageProduct,
  dispatch,
  selectedOptions = [],
}: Props) => {
  const locale = useCurrentLanguage()
  const { t } = useTranslation("shop")
  const photosIfSinglePhotoType = useGetPhotosIfSinglePhotoType(packageProduct.product)

  const qtyIncluded =
    photosIfSinglePhotoType && (packageProduct.qtyIncluded ?? 0) >= photosIfSinglePhotoType.length
      ? photosIfSinglePhotoType.length
      : packageProduct.qtyIncluded

  useEffect(() => {
    if (qtyIncluded !== selectedOptions.length) {
      dispatch(actions.setNumSlots(packageProduct.id, qtyIncluded || 0))
    }
  }, [selectedOptions.length, qtyIncluded, dispatch, packageProduct.id])

  if (!packageProduct.qtyIncluded) return null

  if (!qtyIncluded || (photosIfSinglePhotoType && photosIfSinglePhotoType.length === 0)) return null

  return (
    <>
      {!photosIfSinglePhotoType && (
        <Typography variant="h2" sx={{ mb: 2 }}>
          {t("selectPhotos", { count: qtyIncluded })}
        </Typography>
      )}

      {packageProduct.product.name?.[locale] && (
        <Typography sx={{ mb: 2 }}>
          <NlToBr text={packageProduct.product.name[locale]} />
        </Typography>
      )}

      {packageProduct.product.description?.[locale] && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          <NlToBr text={packageProduct.product.description[locale]} />
        </Typography>
      )}

      <Box display="flex" flexWrap="wrap">
        {new Array(qtyIncluded).fill(null).map((_, index) => {
          const photos =
            photosIfSinglePhotoType && qtyIncluded >= photosIfSinglePhotoType.length
              ? [photosIfSinglePhotoType[index] as Photo]
              : photosIfSinglePhotoType

          return (
            <Box key={index} mr={1} mb={1}>
              <PhotoSelector
                onSelect={(photo: Photo) =>
                  dispatch(actions.selectPhoto(packageProduct.id, index, photo))
                }
                onSelectVariant={(variant: Variant) =>
                  dispatch(actions.selectVariant(packageProduct.id, index, variant))
                }
                selectedPhoto={selectedOptions[index]?.photo}
                selectedVariantSku={
                  selectedOptions[index]?.variant?.sku || packageProduct.product.defaultVariant
                }
                variants={packageProduct.product.variants}
                photosIfSinglePhotoType={photos}
                thumbnail={packageProduct.product.thumbnail}
              />
            </Box>
          )
        })}
      </Box>
    </>
  )
}
