import { styled } from "@mui/material"

// https://jsbin.com/tisaluy/8/edit?html,css,output
// https://github.com/xieranmaya/blog/issues/6

export const Images = styled("section")({
  display: "flex",
  flexWrap: "wrap",
  "&:after": {
    content: "''",
    flexGrow: 99999999,
  },
})

export const ImageWrapper = styled("div")({
  // backgroundColor: "violet",
  position: "relative",
  cursor: "pointer",
  margin: "4px",
})
