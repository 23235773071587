import { Dispatch, ReducerAction } from "react"
import { Photo } from "db"
import cloneDeep from "lodash/cloneDeep"
import remove from "lodash/remove"
import { ThenArg } from "src/app/core/types"
import getPackage from "../queries/getPackage"
import { UpdateShoppingCartWithPackage } from "../../feature-shopping-cart/mutations/updateShoppingCartWithPackage"
import {
  PackageProductOptionsInput,
  ProductOptionsInput,
} from "../../feature-shopping-cart/validations"
import { UpdateShoppingCartWithProduct } from "../../feature-shopping-cart/mutations/updateShoppingCartWithProduct"

export type Pkg = NonNullable<ThenArg<ReturnType<typeof getPackage>>>
export type PackageProduct = Pkg["products"][0]
export type Variant = Pkg["products"][0]["product"]["variants"][0]
export type ProductOptionDispatch = Dispatch<ReducerAction<typeof productOptionReducer>>

type ProductId = number
type ProductQuantities = [packageProductId: ProductId, qtyIncluded: number | null][]
export type Options = {
  photoId: number | null
  photo: Photo | null
  variant: Variant | null
  quantity: number
}[]
export type State = Record<ProductId, Options>

export const createInitialState = (productQuantities: ProductQuantities = []): State => {
  return productQuantities.reduce((agg, [packageProductId, qtyIncluded]) => {
    return {
      ...agg,
      [packageProductId]: new Array(qtyIncluded || 0).fill(null).map(() => ({
        photo: null,
        photoId: null,
        variant: null,
        quantity: 1,
      })),
    }
  }, {})
}

export const actions = {
  deselectPhoto: (productId: ProductId, index: number) => ({
    type: "photoDeselected" as const,
    data: { productId, index },
  }),
  deselectAll: (productId: ProductId, resetToQty: number) => ({
    type: "allDeselected" as const,
    data: { productId, resetToQty },
  }),
  selectPhoto: (productId: ProductId, index: number, photoOrId: number | Photo) => ({
    type: "photoSelected" as const,
    data: {
      productId,
      index,
      photoId: typeof photoOrId === "number" ? photoOrId : null,
      photo: typeof photoOrId === "number" ? null : photoOrId,
    },
  }),
  selectVariant: (productId: ProductId, index: number, variant: Variant) => ({
    type: "variantSelected" as const,
    data: { productId, index, variant },
  }),
  addSlot: (productId: ProductId) => ({
    type: "slotAdded" as const,
    data: { productId },
  }),
  setQuantity: (productId: ProductId, index: number, quantity: number) => ({
    type: "quantitySet" as const,
    data: { productId, index, quantity },
  }),
  setNumSlots: (productId: ProductId, numSlots: number) => ({
    type: "numSlotsSet" as const,
    data: { productId, numSlots },
  }),
}

export const productOptionReducer = (
  state: ReturnType<typeof createInitialState>,
  action: ReturnType<
    | typeof actions["deselectPhoto"]
    | typeof actions["selectPhoto"]
    | typeof actions["selectVariant"]
    | typeof actions["addSlot"]
    | typeof actions["setQuantity"]
    | typeof actions["deselectAll"]
    | typeof actions["setNumSlots"]
  >
) => {
  if (state[action.data.productId]) {
    switch (action.type) {
      case "photoDeselected": {
        const stateCopy = cloneDeep(state)
        const product = stateCopy[action.data.productId]
        if (product) remove(product, (_, index) => index === action.data.index)
        return stateCopy
      }
      case "allDeselected": {
        const stateCopy = cloneDeep(state)
        stateCopy[action.data.productId] = new Array(action.data.resetToQty).fill(null).map(() => ({
          photoId: null,
          photo: null,
          variant: null,
          quantity: 1,
        }))
        return stateCopy
      }
      case "photoSelected": {
        const stateCopy = cloneDeep(state)
        if (!stateCopy[action.data.productId]![action.data.index])
          stateCopy[action.data.productId]!.push({
            photoId: null,
            photo: null,
            variant: null,
            quantity: 1,
          })
        stateCopy[action.data.productId]![action.data.index]!.photoId = action.data.photoId
        stateCopy[action.data.productId]![action.data.index]!.photo = action.data.photo
        return stateCopy
      }
      case "variantSelected": {
        const stateCopy = cloneDeep(state)
        stateCopy[action.data.productId]![action.data.index]!.variant = action.data.variant
        return stateCopy
      }
      case "slotAdded": {
        const stateCopy = cloneDeep(state)
        stateCopy[action.data.productId]!.push({
          photoId: null,
          photo: null,
          variant: null,
          quantity: 1,
        })
        return stateCopy
      }
      case "quantitySet": {
        const stateCopy = cloneDeep(state)
        stateCopy[action.data.productId]![action.data.index]!.quantity = action.data.quantity
        return stateCopy
      }
      case "numSlotsSet": {
        const prevStateForProduct = state[action.data.productId]
        if (!prevStateForProduct) return state

        const newProductState: typeof prevStateForProduct = []

        for (let i = 0; i < action.data.numSlots; i++) {
          if (i < prevStateForProduct.length && prevStateForProduct[i]) {
            newProductState.push(prevStateForProduct[i]!)
          }
        }

        const stateCopy = cloneDeep(state)
        stateCopy[action.data.productId] = newProductState
        return stateCopy
      }
    }
  }
  return state
}

export const stateToPackageProductOptions = (
  state: State
): UpdateShoppingCartWithPackage["packageProductOptions"] | false => {
  const packageProductOptions = Object.entries(state).map(([packageProductId, options]) => {
    return {
      packageProductId: parseInt(packageProductId),
      options: options.map(({ photoId, variant, photo }) => ({
        photoId: photoId || photo?.id,
        variantSku: variant?.sku,
      })),
    }
  })

  try {
    PackageProductOptionsInput.parse(packageProductOptions)
  } catch (e) {
    return false
  }

  return packageProductOptions as UpdateShoppingCartWithPackage["packageProductOptions"]
}

export const stateToProductOptions = (
  state: State,
  productId: number
): UpdateShoppingCartWithProduct["productOptions"] | false => {
  const options = state[productId] ?? []
  const productOptions = options
    .map((option) => ({
      photoId: option.photo?.id || option.photoId,
      variantSku: option.variant?.sku,
      quantity: option.quantity,
    }))
    .filter(({ photoId, variantSku }) => photoId && variantSku)

  try {
    ProductOptionsInput.parse(productOptions)
  } catch (e) {
    return false
  }

  return productOptions as UpdateShoppingCartWithProduct["productOptions"]
}
