import { useEffect, useState } from "react"
import { CircularProgress, Grid } from "@mui/material"
import { Photo } from "db"
import { CloudPhoto } from "src/app/core/components/CloudPhoto"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"
import { useYearCollection } from "../../shared/hooks/useYearCollection"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { PhotoButton } from "./PhotoSelector.styles"
import sortBy from "lodash/sortBy"
import { sortYearCollection } from "../../shared/util"

type Props = {
  photos?: Photo[]
  onSelect: (photo: Photo) => void
}
export const PhotoSelectorPopover = ({ onSelect, photos: photosFromProps = [] }: Props) => {
  const [open, setOpen] = useState(false)
  const student = useActiveStudent()
  const yearCollection = useYearCollection(student.id, useActiveSchoolYear())
  const photos = photosFromProps.length > 0 ? photosFromProps : sortYearCollection(yearCollection)

  useEffect(() => {
    if (!open) {
      const id = setTimeout(() => setOpen(true), 500)
      return () => clearTimeout(id)
    }
  }, [open])

  if (!open) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      {photos.map((photo) => {
        const ratio = (photo.width || 1) / (photo.height || 1)

        return (
          <Grid item key={photo.id}>
            <PhotoButton onClick={() => onSelect(photo)}>
              <CloudPhoto
                photo={photo}
                updatedAt={photo.updatedAt.toISOString()}
                transformation={"fos_thumb"}
                height={75}
                width={ratio * 75}
              />
            </PhotoButton>
          </Grid>
        )
      })}
    </Grid>
  )
}
