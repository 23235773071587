import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material"
import useDisclosure from "src/app/core/hooks/useDisclosure"
import Form, { FORM_ERROR } from "../../../core/components/Form"
import LockOutline from "mdi-material-ui/LockOutline"
import { FormTextField } from "../../../core/components/formFields/FormTextField"
import { useTranslation } from "next-i18next"
import { LoginCode } from "../validations"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import addLoginCode from "../mutations/addLoginCode"
import getAvailableStudents from "../../shared/queries/getAvailableStudents"

export const AddLoginCodeChip = () => {
  const dialog = useDisclosure()
  const successDialog = useDisclosure()
  const { t } = useTranslation("shop")
  const { t: tCommon } = useTranslation("common")
  const [saveLoginCode, addLoginCodeResponse] = useMutation(addLoginCode)

  return (
    <>
      <Chip
        label={t("addLoginCodeButton")}
        variant={"outlined"}
        clickable={true}
        style={{ borderStyle: "dashed" }}
        onClick={dialog.onOpen}
      />

      <Dialog open={successDialog.isOpen} onClose={successDialog.onClose}>
        <DialogContent>
          <Alert>
            {t("addLoginCodeSuccess", { count: addLoginCodeResponse.data?.studentsAdded })}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={successDialog.onClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialog.isOpen} onClose={dialog.onClose}>
        <Form
          schema={LoginCode}
          onSubmit={async (values) => {
            try {
              await saveLoginCode(values)
              await invalidateQuery(getAvailableStudents)
              dialog.onClose()
              successDialog.onOpen()
            } catch (error) {
              return { [FORM_ERROR]: error.toString() }
            }
          }}
          initialValues={{ loginCode: "" }}
        >
          <DialogTitle>{t("addLoginCodeTitle")}</DialogTitle>
          <DialogContent>
            <FormTextField
              name="loginCode"
              label={tCommon("fieldLoginCode")}
              placeholder={tCommon("fieldLoginCodePlaceholder")}
              helperText={tCommon("fieldLoginCodeHelper", {
                defaultValue: `Geef 1 of meerder unieke login codes in door comma gescheiden. Bv: "ABC1234, XYZ9876"`,
              })}
              transform={{
                output: (e) => ({
                  ...e,
                  target: { ...e.target, value: e.target.value.toUpperCase() },
                }),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutline sx={{ opacity: 0.5 }} />
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">{t("add")}</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
