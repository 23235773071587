import { useQuery } from "@blitzjs/rpc";
import { ONE_HOUR } from "src/app/core/libs/dateTime"
import getYearCollection from "../queries/getYearCollection"

export const useYearCollection = (studentId: number, year: number) => {
  const [yearCollection] = useQuery(
    getYearCollection,
    { studentId, year },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )

  return yearCollection
}
