import { actions, ProductOptionDispatch, State, Variant } from "../util/productOption"
import { Typography } from "@mui/material"
import { useTranslation } from "next-i18next"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"
import { PhotoDialog } from "../../feature-photo-dialog/components/PhotoDialog"
import { useLightBox } from "../../../core/hooks/useLightBox"
import { useEffect, useState } from "react"
import without from "lodash/without"
import { ProductDetailVariableQuantityCollection } from "./ProductDetailVariableQuantityCollection"
import { useAvailableStudents } from "../../shared/hooks/useAvailableStudents"
import uniqBy from "lodash/uniqBy"
import { Photo } from "@prisma/client"

type Props = {
  product: { id: number }
  variant: Variant
  dispatch: ProductOptionDispatch
  selectedOptions?: State[number]
}

export const ProductDetailVariableQuantity = ({ product, variant, dispatch }: Props) => {
  const { t } = useTranslation("shop")
  const { qtyIncluded = 0, minQtyIncluded = qtyIncluded } = variant
  const [selected, setSelected] = useState<number[]>([])

  const lightBox = useLightBox()
  const [lightBoxPhoto, setLightBoxPhoto] = useState<Photo>()

  const student = useActiveStudent()

  const availableStudents = useAvailableStudents()
  const allAvailableStudents = uniqBy(
    [...(student.siblings?.students ?? []), ...availableStudents].filter(
      (s) => s.id !== student.id
    ),
    "id"
  )

  const rightAmountOfPhotosSelected =
    selected.length >= (minQtyIncluded as number) && selected.length <= (qtyIncluded as number)

  useEffect(() => {
    dispatch(actions.deselectAll(product.id, minQtyIncluded ?? qtyIncluded ?? 0))
    if (rightAmountOfPhotosSelected) {
      selected.forEach((photoId, index) => {
        dispatch(actions.selectPhoto(product.id, index, photoId))
        dispatch(actions.selectVariant(product.id, index, variant))
      })
    }
    // eslint-disable-next-line
  }, [product.id, rightAmountOfPhotosSelected, selected])

  const handleClickSelect = (photo: Photo) => {
    setSelected((values) => {
      if (values?.includes(photo.id)) {
        return without(values, photo.id)
      }

      if (values.length < (qtyIncluded ?? 0)) {
        return [...values, photo.id]
      }

      return values
    })
  }

  const handleClickItem = (photo: Photo) => {
    setLightBoxPhoto(photo)
    lightBox.onOpen(0)
  }

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        {t("selectVariablePhotos", { qtyIncluded, minQtyIncluded })}
      </Typography>

      <ProductDetailVariableQuantityCollection
        key={student.id}
        student={student}
        isOK={rightAmountOfPhotosSelected}
        selected={selected}
        onClickItem={handleClickItem}
        onClickSelect={handleClickSelect}
      />

      {lightBox.isOpen && lightBoxPhoto && (
        <PhotoDialog
          // @ts-ignore
          photos={[lightBoxPhoto]}
          activeIndex={lightBox.index}
          onClose={lightBox.onClose}
        />
      )}

      {variant.crossCollection &&
        allAvailableStudents.map((student) => (
          <ProductDetailVariableQuantityCollection
            key={student.id}
            student={student}
            isOK={rightAmountOfPhotosSelected}
            selected={selected}
            onClickItem={handleClickItem}
            onClickSelect={handleClickSelect}
          />
        ))}
    </>
  )
}
