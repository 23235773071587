import { useMutation } from "@blitzjs/rpc";
import { LoadingButton } from "@mui/lab"
import CartOutline from "mdi-material-ui/CartOutline"
import { useTranslation } from "next-i18next"
import useDisclosure from "src/app/core/hooks/useDisclosure"
import updateShoppingCart, {
  UpdateShoppingCartWithPackage,
} from "../mutations/updateShoppingCartWithPackage"
import SuccessDialog from "./SuccessDialog"
import { Alert } from "@mui/material"

export const AddPackageToCartButton = ({
  priceListId,
  packageId,
  packageProductOptions,
  disabled,
  year,
  name,
}: UpdateShoppingCartWithPackage & { name?: string; disabled?: boolean }) => {
  const { t } = useTranslation("shop")
  const [addToCartMutation, { isLoading, error }] = useMutation(updateShoppingCart)
  const successDialog = useDisclosure()

  const addToCart = async () => {
    await addToCartMutation({
      priceListId,
      packageId,
      year,
      packageProductOptions,
    }).then(successDialog.onOpen)
  }

  return (
    <>
      <LoadingButton
        loading={isLoading}
        startIcon={<CartOutline />}
        variant="outlined"
        onClick={addToCart}
        disabled={disabled}
      >
        {t("addToCart")}
      </LoadingButton>

      {/* @ts-ignore */}
      {error && <Alert severity="error">{error.message}</Alert>}

      {successDialog.isOpen && <SuccessDialog onClose={successDialog.onClose} name={name} />}
    </>
  )
}
