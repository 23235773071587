import { useState } from "react"

export const useLightBox = () => {
  const [lightBoxIndex, setLightBoxIndex] = useState(-1)
  return {
    index: lightBoxIndex,
    isOpen: lightBoxIndex > -1,
    onClose: () => setLightBoxIndex(-1),
    onOpen: (index: number) => setLightBoxIndex(index),
  }
}
