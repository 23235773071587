import { useQuery } from "@blitzjs/rpc";
import { FC } from "react"
import { Button, Dialog, DialogActions, DialogContent, Alert } from "@mui/material"
import { useTranslation } from "next-i18next"
import { ToCheckoutLink } from "./ToCheckoutLink"
import getShoppingCart from "../queries/getShoppingCart"

type Props = {
  onClose: () => void
  cartLineId?: number
  name?: string
  withoutToCheckoutBtn?: boolean
}
const SuccessDialog: FC<Props> = ({ onClose, cartLineId, name = "", withoutToCheckoutBtn }) => {
  const [, { isLoading }] = useQuery(getShoppingCart, {}, { suspense: false })

  const { t } = useTranslation("shop")
  const { t: tCommon } = useTranslation()

  if (isLoading) {
    return null
  }

  // const cartLine = shoppingCart?.lines.find((line) => line.id === cartLineId)
  //
  // const name = cartLine && getCartLineName(cartLine, lang)

  return (
    <Dialog
      open={true}
      onBackdropClick={onClose}
      maxWidth="sm"
      PaperProps={{ style: { width: 600 } }}
    >
      <DialogContent>
        <Alert color="success" style={{ marginBottom: "2rem" }}>
          {t("addToCartSuccess", { /*context: "package",*/ name })}
        </Alert>
        {/*{cartLine && <CartLine cartLine={cartLine} />}*/}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }} size="small">
          {tCommon("btnClose")}
        </Button>
        {!withoutToCheckoutBtn && (
          <ToCheckoutLink>
            <Button variant="contained" color="primary" onClick={onClose} size="small">
              {t("cartToCheckout")}
            </Button>
          </ToCheckoutLink>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SuccessDialog
