import { Drawer, styled } from "@mui/material"

export const Sidebar = styled(Drawer)(({ theme }) => ({
  "& > .MuiPaper-root": {
    width: "90%",
    maxWidth: 833,
    padding: "66px 92px",

    [theme.breakpoints.down("md")]: {
      padding: "80px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "80px 24px",
      // width: "100%",
    },
  },
}))
