import { styled } from "@mui/material"

export const Selector = styled("button")(({ theme }) => ({
  width: 80,
  height: 80,
  border: `2px dashed ${theme.palette.text.primary}`,
  backgroundColor: theme.palette.grey[50],
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.2s ease",
  position: "relative",
  "&:hover,&.is-over": {
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}))

export const PhotoButton = styled("button")(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.grey[50],
  border: `2px solid transparent`,
  transition: "all 0.2s ease",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}))

export const RemoveButton = styled("button")(({ theme }) => ({
  position: "absolute",
  background: "none",
  border: "none",
  color: theme.palette.error.main,
  cursor: "pointer",
  left: 0,
  top: 0,
  "&:hover": {
    color: theme.palette.error.dark,
  },
}))
