import { useTranslation } from "next-i18next"
import { useQuery } from "@blitzjs/rpc"
import getProduct from "../queries/getProduct"
import { ONE_HOUR } from "../../../core/libs/dateTime"
import { Box, Grid, List, ListItem, ListItemAvatar, Typography } from "@mui/material"
import { useCurrentLanguage } from "../../../core/hooks/useCurrentLanguage"
import { NlToBr } from "../../../core/components/NlToBr"
import { useYearCollection } from "../../shared/hooks/useYearCollection"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"
import groupBy from "lodash/groupBy"
import { Fragment } from "react"
import { CloudPhoto } from "../../../core/components/CloudPhoto"
import { AddProductToCartButton } from "../../feature-shopping-cart/components/AddProductToCartButton"
import { formatMoney } from "../../../core/libs/money"
import sortBy from "lodash/sortBy"

type Props = {
  id: number
}
export const DownloadProductDetail = ({ id }: Props) => {
  const { t } = useTranslation("shop")
  const locale = useCurrentLanguage()
  const student = useActiveStudent()
  const schoolYear = useActiveSchoolYear()
  const collections = useYearCollection(student.id, schoolYear)
  const [product] = useQuery(
    getProduct,
    { productId: id },
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )

  // TODO: For now we only support 1 variant
  const productVariant = product?.variants.find((variant) => variant.sku === product.defaultVariant)

  if (!product || !productVariant) return null

  const variationIds = productVariant.photoTypePricing.map((p) => p.variationId)

  const photos =
    variationIds.length > 0
      ? collections.flatMap((collection) =>
          collection.photos.filter((photo) => variationIds.includes(photo.variationId ?? 0))
        )
      : collections.flatMap((collection) => collection.photos)

  const photosPerVariation = groupBy(photos, "variationId")

  const pricings =
    productVariant.photoTypePricing.length > 0
      ? sortBy(productVariant.photoTypePricing, "price")
      : Object.keys(photosPerVariation).map((variationId) => ({
          id: variationId,
          variationId: variationId,
          price: productVariant.price,
        }))

  return (
    <section>
      <Typography variant="h1" gutterBottom>
        {product.name?.[locale]}
      </Typography>
      {product.description?.[locale] && (
        <Typography variant="body2" sx={{ mb: 3 }}>
          <NlToBr text={product.description[locale]} />
        </Typography>
      )}
      <List>
        {pricings.map((pricing) => (
          <Fragment key={pricing.id}>
            {photosPerVariation[pricing.variationId]?.map((photo) => {
              const width = photo.width ?? 1
              const height = photo.height ?? 1
              const itemHeight = 120

              return (
                <ListItem key={photo.id}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box
                        sx={{
                          width: (width * itemHeight) / height,
                          height: itemHeight,
                          position: "relative",
                        }}
                      >
                        <CloudPhoto
                          photo={photo}
                          updatedAt={photo.updatedAt?.toISOString()}
                          transformation={"fos_thumb"}
                          layout="fill"
                          objectFit="contain"
                          style={{ borderRadius: 4 }}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ mr: 4 }}>
                        <small>{t("price")}</small>
                        <br />
                        <span style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                          {formatMoney(pricing.price)}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <AddProductToCartButton
                        priceListId={product.priceListId}
                        productId={product.id}
                        name={`${student.name} - ${photo.variation?.name} - ${product.name?.[locale]}`}
                        productOptions={[
                          {
                            photoId: photo.id,
                            variantSku: productVariant.sku,
                            quantity: 1,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </Fragment>
        ))}
      </List>
    </section>
  )
}
