import sortBy from "lodash/sortBy"
import { Collection, Photo, PhotoSessionTimeVariation } from "db"
import uniqBy from "lodash/uniqBy"

type YearCollection = (Collection & {
  photos: (Photo & { variation: PhotoSessionTimeVariation | null; highRes?: string })[]
})[]

export const sortYearCollection = (yearCollection: YearCollection) => {
  return uniqBy(
    sortBy(
      yearCollection.reduce((prev, collection) => [...prev, ...collection.photos], []),
      (photo) => {
        return photo.variation?.sortOrder
        // return [photo.variation?.sortOrder, (photo.width || 1) / (photo.height || 1)]
      }
    ),
    "publicId"
  )
}
