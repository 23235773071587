import { lighten, styled, Typography } from "@mui/material"

export const CardIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}))
export const CardIconCircle = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: "100%",
  width: 64,
  height: 64,
  padding: 8,
}))

export const Card = styled(
  ({ color, ...props }: JSX.IntrinsicElements["button"] & { color: "primary" | "secondary" }) => (
    <button {...props} />
  )
)(({ theme, color }) => ({
  border: "none",
  cursor: "pointer",
  width: "100%",
  height: "100%",
  padding: theme.spacing(4),
  backgroundColor: lighten(theme.palette[color].light, 0.6),
  transition: "box-shadow ease 0.2s",
  // borderRadius: theme.shape.borderRadius,
  "&:hover": {
    boxShadow: "0 0 20px rgba(0,0,0,.1)",
  },
}))

export const CardButton = styled(Typography)(({ theme }) => ({
  display: "block",
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  backgroundColor: theme.palette.background.default,
  textTransform: "uppercase",
  fontFamily: theme.typography.fontFamilyAlt,
  letterSpacing: 1,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: "1.4rem",
}))

export const CardDescription = styled(Typography)(({ theme }) => ({
  display: "block",
}))

export const CardPrice = styled(Typography)(({ theme }) => ({
  display: "block",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  fontFamily: theme.typography.fontFamilyAlt,
  letterSpacing: 2,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: "2rem",
  "& small": {
    fontWeight: "normal",
  },
}))
